<template>
  <b-container fluid>
    <Teleport to="#header-buttons">
      <div>
        <b-button
          id="show-btn"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="openVenueModal"
        >
          Add Brands
        </b-button>
      </div>
    </Teleport>
    <b-row>
      <b-col
        cols="12"
        class="px-0"
      >
        <b-card
          no-body
          class="mt-lg-0 mt-2"
          title="All Venues"
        >
          <AllBrands
            ref="allVenues"
          />
        </b-card>
      </b-col>
    </b-row>
    <CreateBrands
      ref="add-brand"
      @reloadBrands="$refs.allVenues.loadBrands()"
    />
  </b-container>
</template>
<script>

import {
  BContainer, BRow, BCol, BCard, BButton,
} from 'bootstrap-vue'
import Teleport from 'vue2-teleport'
import AllBrands from './AllBrands.vue'
import CreateBrands from '@/components/brands/CreateBrands.vue'
import AccountTypes from '@/common/enums/accountTypeEnum'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BButton,
    AllBrands,
    CreateBrands,
    Teleport,
  },
  data() {
    return {
      AccountTypes,
    }
  },
  computed: {
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
  },
  methods: {
    openVenueModal() {
      this.$refs['add-brand'].show()
    },
  },
}
</script>
